import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import theme from './src/tokens/theme';
import { Globals } from './src/shared/globals';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Globals theme={theme} />
    {element}
  </ThemeProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.object.isRequired
};
