export default {
  primary: '#14373c',
  text: '#4a3e3e',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#A2A2A2',
  pink: 'rgba(247, 119, 212, 1)',
  lightBlue: '#F1F8FB',
  browser: {
    red: '#ed1c24',
    orange: '#f7941d',
    green: '#8dc63f'
  },
  background: {
    pink: '#FFBDED',
    header: '#15373c',
    blue2: '#BAEAFF',
    yellow: '#F8E71C'
  }
};
