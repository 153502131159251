import { Global, css } from '@emotion/core';
import emotionReset from 'emotion-reset';
import 'segoe-fonts';
import PropTypes from 'prop-types';
import { ParisishWoff, ParisishWoff2 } from './fonts';

export const Globals = ({ theme }) => (
  <Global
    styles={css`
      ${emotionReset}

      * {
        box-sizing: border-box;
      }

      @font-face {
        font-family: 'Parisish';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: U+000-5FF; /* Download only latin glyphs */
        src: local('Parisish'), url(${ParisishWoff2}) format('woff2'),
          url(${ParisishWoff}) format('woff');
      }

      body {
        font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont,
          Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 400;
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: ${theme.letterSpacings.text};
      }

      body {
        h1,
        h2,
        h3,
        h4 {
          font-family: ${theme.fonts.secondary};
          font-weight: ${theme.fontWeights.bold};
        }

        h1 {
          font-size: ${theme.fontSizes[6]}px;
          line-height: ${theme.lineHeights[5]}px;
          color: ${theme.colors.primary};
        }
        h2 {
          font-size: ${theme.fontSizes[5]}px;
          line-height: ${theme.lineHeights[4]}px;
          color: ${theme.colors.primary};
        }
        h3 {
          font-size: ${theme.fontSizes[4]}px;
          line-height: ${theme.lineHeights[3]}px;
          color: ${theme.colors.primary};
        }
        h4 {
          font-size: ${theme.fontSizes[3]}px;
          line-height: ${theme.lineHeights[2]}px;
          // letter-spacing: ${theme.letterSpacings.text};
          font-weight: ${theme.fontWeights.normal};
          color: ${theme.colors.primary};
        }
        p,
        span {
          line-height: ${theme.lineHeights[2]}px;
          color: ${theme.colors.text};
          word-break: keep-all;
        }

        a,
        button {
          border: none;
          text-decoration: none;
          color: ${theme.colors.primary};
          cursor: pointer;
          background-color: transparent;
          padding: 0;
        }
      }
    `}
  />
);

Globals.propTypes = {
  theme: PropTypes.object.isRequired
};
